import React, { useState } from 'react'
import styled from "@emotion/styled"
import Img from 'gatsby-image'
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useStaticQuery, navigate } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const TeamFormContainer = styled.div`
position: relative;
margin: 60px 0;
@media(min-width: 768px){
    margin: 0;
}
`
const StyledImg = styled(Img)`

`
const FormContainer = styled.div`
background-color: #fff;
box-shadow: 0 0 10px #ccc;
padding: 15px;
margin: 0 auto;
border: 1px solid #ccc;
max-width: 83%;

@media(min-width: 992px){
    position: absolute;
    bottom: -20%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 20%);
    min-width: 692px;
    padding: 50px;
}
@media(min-width: 1367px){
    width: 70%;
}
@media(min-width: 1600px){
    bottom: -10%;
    transform: translate(-50%, 10%);
}
`
const H4SpanContainer = styled.div`
text-align: center;
margin-bottom: 30px;
@media(min-width: 1367px){
    margin-top: 80px;
}
`
const StyledH4 = styled.h4`
font-size: 30px;
letter-spacing: 2px;
color: #0e1f38;
margin-bottom: 26px;
font-family: "Playfair Display";
font-weight: 500;
line-height: 1.2;
@media(min-width: 992px){
    font-size: 48px;
}
`
const StyledSpan = styled.span`
color: #b6212a;
font-weight: 600;
text-transform: uppercase;
`
const FieldStyled = styled(Field)`
display: block;
width: 100%;
font-size: 12px;
border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding-left: 0;
    margin-bottom: 40px;
    padding: .375rem .75rem .375rem 0;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    overflow: visible;

`
const FieldWrapper = styled.div`
padding: 0 15px;

label{
    text-transform: uppercase;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    margin-bottom: .5rem;
}
`
const ButtonWrapper = styled.div`
color: #b6212a;
font-size: 12px;
background: 0 0;
    border: 1px solid #b6212a;
    border-radius: 0;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 46px;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
    :hover{
        color: #fff;
        background-color: #b6212a;
    }
    :after{
        content: "";
    position: absolute;
    width: calc(100% - 18px);
    height: 131%;
    border: 1px solid #b6212a;
    left: 10px;
    right: 0;
    top: -7px;
    }
`
const ButtonWrapperWrapper = styled.div`
text-align: center;

button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: none;
}
button:hover{
  cursor: pointer;
}
`
const SubmitError = styled.div`
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  bottom: 1rem;
  left: 0;
  width: 100%;
  color: crimson;

`
const Req = styled.span`
color: #b6212a;
padding-left: 5px;
`
const GridDesktop = styled.div`
@media(min-width: 992px){
    display: grid;
    grid-template-columns: repeat(4, 25%);
}
`
function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const Schema = Yup.object().shape({
    first_name: Yup.string()
      .required("Please enter your first name."),
    last_name: Yup.string()
      .required("Please enter your last name."),
      phone_number: Yup.string()
      .required("Please enter your phone number.")
      .matches(phoneRegExp, 'Please enter your phone number in the following format: 555-555-5555'),
    email_address: Yup.string()
      .email("Please enter your email in the following format: example@sample.com")
      .required("Please enter your email address."),
    describe_your_case: Yup.string()
    .required("Please enter a description"),
  })
  const HomeFreeConsultation = ({}) => {
    const breakpoints = useBreakpoint();
    const [error, setError] = useState(false)
    function handleSubmit(values, setSubmitting, resetForm) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "freeconsultation",
          ...values,
        }),
      })
        .then(() => {
          navigate("/thank-you/", {
            state: { submitted: true },
          })
        })
        .catch(() => {
          resetForm()
          setSubmitting(false)
          setError(true)
        })
    }
    const data2 = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "large-team-image.png" }) {
          childImageSharp {
            fluid(maxHeight: 872, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
  `) 
  return(
<TeamFormContainer id="homeForm">
{breakpoints.sm ? 
    null
    : <StyledImg fluid={data2.file.childImageSharp.fluid} loading="lazy"/>}
    <FormContainer>
        <H4SpanContainer>
        <StyledH4>
            Request A Free Consultation
        </StyledH4>
        <StyledSpan>
            Fill out the form below
        </StyledSpan>
        </H4SpanContainer>
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        phone_number: "",
        email_address: "",
        describe_your_case: "",
      }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm)
      }}
    >
        {({ isSubmitting }) => (
             <Form
             method="post"
             name="freeconsultation"
             data-netlify="true"
             data-netlify-honeypot="bot-field"
           >
             <input type="hidden" name="bot-field" />
             <input type="hidden" name="form-name" value="freeconsultation" />
   <GridDesktop>
             <FieldWrapper>
               <label htmlFor="first_name">First Name <Req>*</Req></label>
             <FieldStyled type="text" name="first_name" placeholder="Your First Name Here" required/>
                   <div className="error">
                 <ErrorMessage name="first_name" />
               </div>
                   </FieldWrapper>

                   <FieldWrapper>
               <label htmlFor="last_name">Last Name <Req>*</Req></label>
             <FieldStyled type="text" name="last_name" placeholder="Your Last Name Here"  required/>
                   <div className="error">
                 <ErrorMessage name="last_name" />
               </div>
                   </FieldWrapper>

                   <FieldWrapper>
            <label htmlFor="phone_number">Phone <Req>*</Req></label>
            <FieldStyled type="tel" name="phone_number" className="input" placeholder="Your Phone Number Here"  required/>
            <div className="error">
              <ErrorMessage name="phone_number" />
            </div>
          </FieldWrapper>

          <FieldWrapper>
            <label htmlFor="email">Email <Req>*</Req></label>
            <FieldStyled type="email" name="email_address" className="input" placeholder="Your Email Here"  required/>
            <div className="error">
              <ErrorMessage name="email_address" />
            </div>
          </FieldWrapper>
          </GridDesktop>
          <FieldWrapper>
            <label htmlFor="describe_your_case">Describe Your Case <Req>*</Req></label>
            <FieldStyled name="describe_your_case" component="textarea" rows="2" className="input" placeholder="Describe Your Case"  required/>
            <div className="error">
              <ErrorMessage name="describe_your_case" />
            </div>
          </FieldWrapper>
          <ButtonWrapperWrapper>
          <button type="submit" disabled={isSubmitting}>
                   <ButtonWrapper>
            
              Submit Your Case
            
          </ButtonWrapper>
          </button>
          </ButtonWrapperWrapper>
          {error && (
            <SubmitError>Something went wrong. Please try again!</SubmitError>
          )}
                   </Form>
        )}
    </Formik>
    </FormContainer>
</TeamFormContainer>
  )
  }
  export default HomeFreeConsultation